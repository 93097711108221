import "core-js/modules/web.timers.js";
import { EventBus } from '@adeo/kobi--front-helpers';
import { onUserConsentChange } from "../autocontextualization/autocontextualization.src";
import { autoContextUserFromStoreIdAndPostCode } from "../autocontextualization/services/auto-context-service";
import { retrieveCookieValueByName } from "./cookie-finder";
export var STORE_HEADER_MODULE_EVENT_BUS_NAME = "store-module-header-event-bus";
export var OPEN_STORE_CONTEXT_LAYER_EVENT = "openStoreContextLayer";
export var OPENING_STORE_CHOICES_MODAL_EVENT = "autocontext:choicestore:openmodal";
export var SET_STORE_EVENT = "autocontext:store:set";
export var USER_CONSENT_EVENT = "PrivacyChange";
export var USER_AUTO_CONTEXT_SUCCESS_EVENT = 'userAutoContextSuccess';
initStoreHeaderEventBus();
listenUserConsentEvent();
emitModalEventWhenNoConsentOrContext();
var eventBus = window.storeHeaderModule.eventBus;
function initStoreHeaderEventBus() {
  if (!window.storeHeaderModule) {
    window.storeHeaderModule = {};
  }
  if (!window.storeHeaderModule.eventBus) {
    window.storeHeaderModule.eventBus = new EventBus(STORE_HEADER_MODULE_EVENT_BUS_NAME);
  }
}
function listenUserConsentEvent() {
  window.addEventListener(USER_CONSENT_EVENT, onUserConsentChange);
}
function emitModalEventWhenNoConsentOrContext() {
  setTimeout(function () {
    var consentCookie = retrieveCookieValueByName('OptanonConsent');
    var contextCookie = retrieveCookieValueByName('customer_context');
    if (!consentCookie && !contextCookie) {
      dispatchOpeningStoreChoicesModalEvent();
    }
  }, 3000);
}
export function dispatchCustomerContextSuccessEvent() {
  var event = new CustomEvent(USER_AUTO_CONTEXT_SUCCESS_EVENT, {
    detail: {
      isUserAutoContextSuccess: true
    }
  });
  window.dispatchEvent(event);
}
export function dispatchCustomerAutoContextFailureEvent() {
  var event = new CustomEvent(USER_AUTO_CONTEXT_SUCCESS_EVENT, {
    detail: {
      isUserAutoContextSuccess: false
    }
  });
  window.dispatchEvent(event);
}
export function dispatchOpenStoreContextLayerEvent() {
  // TODO: Use eventBus instead and see if wait for the layer to be ready before opening it.
  setTimeout(function () {
    window.dispatchEvent(new Event(OPEN_STORE_CONTEXT_LAYER_EVENT));
  }, 3000);
}
export function dispatchOpeningStoreChoicesModalEvent() {
  eventBus.emit(OPENING_STORE_CHOICES_MODAL_EVENT);
}
eventBus.on(SET_STORE_EVENT, function (_ref) {
  var detail = _ref.detail;
  autoContextUserFromStoreIdAndPostCode(detail.storeId, detail.postcode);
});